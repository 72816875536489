<template>
  <div class="content-box-green">
    <div class="title">Debt Yield Calculator - Maturity Date {{ toLocaleDate(this.property.loan.maturity_date) }}</div>
    <div class="content-body">
      <div class="row mb-2">
        <div class="col-4">
          <p class="mb-0">Current NOI</p>
          <p class="mb-0 d-inline">${{ commaDelimited(this.property.loan.noi) }}</p>
        </div>
        <div class="col-4">
          <p class="mb-0">Current Loan</p>
          <p class="mb-0 d-inline">${{ commaDelimited(this.property.total_balance) }}</p>
        </div>
        <div class="col-4">
          <p class="mb-0">Current Debt Yield</p>
          <p>{{ this.property.loan.debt_yield_noi }}</p>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-4">
          <p class="mb-0">Market Debt Yield</p>
          <div class="input-group w-75">
            <input type="text" class="form-control" v-model="debtYieldToUse">
            <span class="input-group-text">%</span>
          </div>
        </div>
        <div class="col-4">
          <p class="mb-0">New Loan</p>
          <p class="d-inline">${{ commaDelimited(newLoanAmount) }}</p>
        </div>
        <div class="col-3">
          <p class="mb-0">Shortfall</p>
          <b>${{ commaDelimited(shortfall()) }}</b>
        </div>
      </div>
      <div class="row mb-2 d-none">
        <div class="col-3">
          <p class="mb-0">Interest Rate</p>
          <div class="input-group">
            <input type="text" class="form-control" v-model="interest_rate">
            <span class="input-group-text">%</span>
          </div>
        </div>
        <div class="col-3">
          <p class="mb-0">Current DSCR</p>
          <p class="mb-0 d-inline">{{ this.property.loan.dscr_noi }}</p>
        </div>
        <div class="col-3">
          <p class="mb-0">New DSCR</p>
          <b>{{  }}</b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  props: {
    property: Object
  },
  data() {
    return {
      interest_rate: 5.85,
      cap_rate: 7,
      ltv: 60,
      debtYield: 0,
      debt_yield_shortfall: {
        industrial: 11,
        multifamily: 9,
        retail: 12,
        office: 13.5,
        hotel: 18.5,
        self_storage: 9.5,
        mixed_use: 12,
        default: 8
      }
    }
  },
  created() {
    // Initialize debtYield based on the mapping
    const mappedType = this.debt_yield_map(this.property);
    this.debtYield = this.debt_yield_shortfall[mappedType] || this.debt_yield_shortfall.default;
  },
  computed: {
    debtYieldToUse: {
      get() {
        return this.debtYield; // Return the current value of debtYield
      },
      set(newValue) {
        this.debtYield = parseFloat(newValue) || 0; // Update debtYield with the new value
      },
    },
    propertyValue() {
      return this.property.loan.noi / ( this.cap_rate / 100 )
    },
    loanAmount() {
      return this.propertyValue * ( this.ltv / 100 )
    },
    newLoanAmount() {
      return this.property.loan.noi / (this.debtYieldToUse / 100)
    },
  },
  methods: {
    toLocaleDate(dateString) {
      if (dateString == undefined || dateString == null) {
        return dateString;
      }
            
      let date = new Date(`${dateString}T00:00:00Z`).toLocaleDateString('en-US', {timeZone: 'UTC'});
      
      return date; 
    },
    commaDelimited(value) {
      if (value == undefined || value == null) {
        return value;
      }
    
      return value.toLocaleString("en", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    debt_yield_map(property) {
      const mapping = {
        'RT-': 'retail',
        'OF-': 'office',
        'LO': 'hotel',
        'MF-': 'multifamily',
        'SS': 'self_storage',
        'IN': 'industrial',
        'MU': 'mixed_use'
      };
      
      for (const prefix in mapping) {
        if (property.normalized_property_type?.startsWith(prefix)) {
          return mapping[prefix];
        }
      }
      
      return 'default';
    },
    shortfall() {
      let excessProceeds = this.property.total_balance - this.newLoanAmount;
      
      if (excessProceeds < 0) {
        return 0
      } else {
        return excessProceeds
      }
    },
  },
}
</script>

<style scoped>

b { 
  font-size: 12px;
}

.content-body {
  border: 1px solid #DDD;
  padding: 5px 10px 0px 10px;
}

.form-control, .input-group-text {
  height: 25px;
}

.input-group-text {
  font-size: .75rem !important;
  padding: 10px;
}

table {
  table-layout: fixed;
  width: 100%;
}

.table-sm {
  font-size: .75rem;
  border-color: #DDD
}

tr td:nth-child(2) {
  text-align: right;
}

.content-box-green {
  margin-bottom: 10px;
}
  
.content-box-green .content {
  overflow: hidden;
  padding: 10px;
  font-size: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #4CAF50;
}

.content-box-green .title {
  height :30px;
  line-height: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #4CAF50;
  font-size: 12px;
  font-weight: bold;
  display: block;
  color: white;
  display: block;
  padding: 0px 5px;
  border: 1px solid #4CAF50;
  border-bottom: none;
}

p, input[type="text"] {
  font-size: 12px;
}

</style>